/* Teaser Light Background Variant */
.background-color--white .card__top,
.background-color--white .card__bottom {
  color: #72686b;

  /* Grey */
}

.background-color--white .card__top:hover {
  color: #000;

  /* True Black */
}

.background-color--white .card__title-span {
  background-image: linear-gradient(#c2c700, #c2c700);

  /* Yellow */
}

.authors__author-link.authors__author-link--cards {
  border-color: #2c2729;
}

.background-color--white .authors__author-link--cards {
  border-color: #f3f2ef;

  /* White */
}

.card__title {
  position: relative;
  margin-top: 0em;
  margin-left: 0em;
  font-size: 1.5rem;
  line-height: 1.2;
}

@media screen and (max-width: 991px) {
  .card__title {
    font-size: 1.5rem;
    letter-spacing: -0.01em;
  }
}

@media screen and (max-width: 479px) {
  .card__title {
    font-size: 1.25rem;
  }
}

.card__title.h3 {
  z-index: 1;
  font-size: 2.25rem;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

@media screen and (max-width: 991px) {
  .card__title.h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .card__title.h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 479px) {
  .card__title.h3 {
    font-size: 1.25rem;
    letter-spacing: -0.01em;
  }
}

.card__title-span {
  margin-right: -0.25em;
  margin-left: -0.25em;
  padding-right: 0.25em;
  padding-left: 0.25em;
  -webkit-transition: background-size 320ms cubic-bezier(0.23, 1, 0.32, 1), color 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: background-size 320ms cubic-bezier(0.23, 1, 0.32, 1), color 320ms cubic-bezier(0.23, 1, 0.32, 1);
  background-image: linear-gradient(rgba(57, 110, 234, 0.5), rgba(57, 110, 234, 0.5));
  background-repeat: no-repeat;

  /* Blue */
  background-position: 0% 50%;
  background-size: 0 0.175em;
}

.card__top {
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  color: #b2b2ab;
  font-weight: 400;
}

.card__top:hover {
  color: #fff;
}

.card__top:hover .card__title-span {
  background-size: 100% 0.175em;
}

.card__bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #b2b2ab;
}

.card__description {
  font-size: 0.9rem;
}

.card__cta {
  font-size: 0.9rem;
  font-weight: 800;
  line-height: 1.1;
}

/* Insights featured card. */
.gridlist--three .gridlist__cell--featured {
  min-width: calc(66.6666% - 4rem);
}

@media screen and (max-width: 991px) {
  .gridlist--three .gridlist__cell--featured {
    min-width: calc(100% - 4rem);
  }
}

.gridlist__cell--featured .circle--halo {
  top: -3rem;
  left: -3rem;
}

.gridlist__cell--featured .card__top {
  color: #231f20;

  /* Black */
}

.gridlist__cell--featured .card__description {
  font-size: 1.25rem;
  letter-spacing: -0.01em;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .gridlist__cell--featured .card__description {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 479px) {
  .gridlist__cell--featured .card__description {
    font-size: 1rem;
    letter-spacing: 0em;
  }
}

.gridlist__cell--featured .card__bottom {
  font-size: 1.25rem;
}

@media screen and (max-width: 991px) {
  .gridlist__cell--featured .card__bottom {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 479px) {
  .gridlist__cell--featured .card__bottom {
    font-size: 1rem;
  }
}
