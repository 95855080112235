/* Typography styles */

h1 {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: -0.03em;
  font-family: Larken, 'Times New Roman', sans-serif;
  font-size: 4rem;
  font-weight: 900;
  letter-spacing: -0.02em;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.25rem;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 1.75rem;
  }
}

h2 {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: -0.03em;
  font-family: Inter, sans-serif;
  font-size: 3.25rem;
  font-weight: 800;
  letter-spacing: -0.03em;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  h2 {
    font-size: 2.5rem;
    line-height: 1.1;
  }
}

@media screen and (max-width: 767px) {
  h2 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.75rem;
    letter-spacing: -0.02em;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 1.5rem;
  }
}

h3 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  margin-left: -0.03em;
  font-family: Inter, sans-serif;
  font-size: 2.25rem;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

@media screen and (max-width: 991px) {
  h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  h3 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 479px) {
  h3 {
    font-size: 1.25rem;
    letter-spacing: -0.01em;
  }
}

h4 {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 800;
  letter-spacing: -0.02em;
  line-height: 1.15;
}

@media screen and (max-width: 991px) {
  h4 {
    letter-spacing: -0.01em;
  }
}

@media screen and (max-width: 767px) {
  h4 {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 479px) {
  h4 {
    font-size: 1.25rem;
  }
}

h5,
.h5 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  line-height: 1.4;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  h5,
  .h5 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 479px) {
  h5,
  .h5 {
    font-size: 0.75rem;
  }
}

h6 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.4;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  h6 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 479px) {
  h6 {
    font-size: 0.75rem;
  }
}

.h1 {
  margin-top: 4rem;
  margin-bottom: 2rem;
  margin-left: -0.03em;
  font-family: Larken, 'Times New Roman', sans-serif;
  font-size: 6.5vw;
  font-weight: 900;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  .h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .h1 {
    margin-top: 2rem;
    margin-bottom: 1rem;
    font-size: 2.25rem;
    letter-spacing: 0em;
  }
}

@media screen and (max-width: 479px) {
  .h1 {
    font-size: 1.75rem;
  }
}

.h1.h1--small {
  font-size: 4.5vw;
  letter-spacing: 0em;
  line-height: 1.1;
}

@media screen and (max-width: 991px) {
  .h1.h1--small {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 479px) {
  .h1.h1--small {
    font-size: 1.75rem;
  }
}

.h1.h1--sans {
  font-family: Inter, sans-serif;
  font-weight: 800;
}

.h2 {
  margin-left: -0.03em;
  font-size: 3.25rem;
  font-weight: 800;
  letter-spacing: -0.03em;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  .h2 {
    font-size: 2.75rem;
  }
}

@media screen and (max-width: 767px) {
  .h2 {
    font-size: 1.75rem;
    letter-spacing: -0.02em;
  }
}

@media screen and (max-width: 479px) {
  .h2 {
    font-size: 1.5rem;
  }
}

.h2--serif {
  margin-left: 0em;
  font-family: Larken, 'Times New Roman', sans-serif;
  font-size: 4vw;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.02em;
}

@media screen and (max-width: 991px) {
  .h2--serif {
    font-size: 2.5rem;
    line-height: 1.1;
  }
}

@media screen and (max-width: 767px) {
  .h2--serif {
    font-size: 2rem;
  }
}

@media screen and (max-width: 479px) {
  .h2--serif {
    font-size: 1.5rem;
  }
}

.h3 {
  z-index: 1;
  font-size: 2.25rem;
  letter-spacing: -0.02em;
  line-height: 1.1;
}

@media screen and (max-width: 991px) {
  .h3 {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) {
  .h3 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 479px) {
  .h3 {
    font-size: 1.25rem;
    letter-spacing: -0.01em;
  }
}

.h4 {
  font-size: 1.5rem;
  letter-spacing: -0.02em;
  line-height: 1.15;
  text-transform: none;
}

@media screen and (max-width: 991px) {
  .h4 {
    letter-spacing: -0.01em;
  }
}

@media screen and (max-width: 479px) {
  .h4 {
    font-size: 1.25rem;
  }
}

.h5 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-left: 0em;
  font-family: Inter, sans-serif;
  font-size: 0.9rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  line-height: 1.4;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .h5 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 479px) {
  .h5 {
    font-size: 0.75rem;
  }
}

.h6 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  line-height: 1.4;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .h6 {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 479px) {
  .h6 {
    font-size: 0.75rem;
  }
}

.block__title {
  margin-top: 0rem;
}

@media screen and (max-width: 991px) {
  .block__title {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 479px) {
  .block__title {
    margin-bottom: 1rem;
  }
}

p {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 767px) {
  p {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 479px) {
  p {
    font-size: 0.9rem;
  }
}

hr {
  height: 0.6rem;
  margin: 4rem 0;
  border: none;
  background-color: #d3d3cc;
}

hr.hr--tight {
  margin: 2rem 0;
}

.note__text {
  color: rgba(44, 40, 41, 0.85);
  font-size: 1.75vw;
  font-style: italic;
  font-weight: 800;
  line-height: 1.1;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 991px) {
  .note__text {
    font-size: 2.25vw;
  }
}

@media screen and (max-width: 767px) {
  .note__text {
    font-size: 2.75vw;
  }
}

strong {
  font-weight: 800;
}

em {
  font-family: Larken, 'Times New Roman', sans-serif;
  font-size: 108%;
  font-style: italic;
}

.p--large {
  font-size: 1.25rem;
  line-height: 1.4;
}

@media screen and (max-width: 767px) {
  .p--large {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 479px) {
  .p--large {
    font-size: 1rem;
    line-height: 1.4;
  }
}

.p--small {
  font-size: 0.8rem;
  letter-spacing: 0.02em;
}

@media screen and (max-width: 479px) {
  .p--small {
    font-size: 0.75rem;
  }
}

.block__footnote {
  top: calc(100% + 1.5rem);
}

.p--small.block__footnote {
  position: absolute;
  margin-bottom: 0em;
}

.p--small.p--footnote {
  margin-top: 1.5rem;
}

/* Links */

a {
  position: relative;
  z-index: 1;
  margin-right: -0.25em;
  margin-left: -0.25em;
  padding-right: 0.25em;
  padding-left: 0.25em;
  transition: background-size 320ms cubic-bezier(0.23, 1, 0.32, 1), color 320ms cubic-bezier(0.23, 1, 0.32, 1);
  color: #2d65e7;
  font-weight: 800;
  text-decoration: none;
}

a:hover {
  color: #000;
}

/* Default link styles. */
li a,
p a,
.a--dark,
.ux__grid-caption a,
.label--checkbox a,
.body--light .block--tiles .tile--graphic a {
  background-image: linear-gradient(to bottom, rgb(194, 199, 0), rgb(194, 199, 0));
  background-repeat: no-repeat;

  /* Yellow */
  background-position: 0% 50%;
  background-size: 0% 0.175em;
}

li a:hover,
p a:hover,
.a--dark:hover,
.ux__grid-caption a:hover,
.label--checkbox a:hover,
.body--light .block--tiles .tile--graphic a:hover {
  background-size: 100% 0.175em;
}

/* Links on dark backgrounds. */
.a--dark,
.background-color--black li a,
p.background-color--black a,
.background-color--black p a,
.ux__grid-caption a,
.background-gradient--secondary a,
.footer__menu-item--copyright a,
.checkbox__message a,
.input__checkbox--white-text a,
.teasers p > a {
  /* Yellow */
  background-image: linear-gradient(to bottom, rgba(57, 110, 234, 0.5), rgba(57, 110, 234, 0.5));
  color: #c2c700;

  /* Blue + translucency */
}

.a--dark:hover,
.background-color--black li a:hover,
p.background-color--black a:hover,
.ux__grid-caption a:hover,
.background-gradient--secondary a:hover,
.footer__menu-item--copyright a:hover,
.checkbox__message a:hover,
.input__checkbox--white-text a:hover,
.teasers p > a:hover {
  color: #f3f2ef;

  /* Soft White */
}

/* Text utility classes */
.text-align--left,
.text-align--left * {
  text-align: left !important;
}

.text-align--right {
  text-align: right !important;
}

.text-align--center {
  text-align: center !important;
}

.text-shadow {
  color: #fff;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.3), 0 5px 20px rgba(0, 0, 0, 0.5);
}

.strikethrough {
  text-decoration: line-through;
}

.code {
  padding-right: 0.25em;
  padding-left: 0.25em;
  border-radius: 0.25em;
  background-color: #d3d3cc;
  font-family: 'JetBrains Mono', sans-serif;
}

/* Target inline `code` elements. */
code:not(pre code) {
  padding: 1px 3px 2px 3px;
  border-radius: 3px;
  background-color: #1d1f21;
  color: #c5c8c6;
}

h2 code:not(pre code),
h3 code:not(pre code),
h4 code:not(pre code),
h5 code:not(pre code),
h6 code:not(pre code) {
  background: none;
  color: #72686b;
}

.pre {
  margin-bottom: 2em;
  padding: 2em 2.5em;
  overflow: scroll;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  border-color: #d3d3cc;
  background-color: #ededea;
  font-family: 'JetBrains Mono', sans-serif;
}

@media screen and (max-width: 767px) {
  .pre {
    padding: 1.5em;
  }
}

/* Superscript modifier */
.sup {
  font-size: 38.197%;
  letter-spacing: 0em;
  vertical-align: 1.2em;
}

/* Lists */
ul {
  margin-top: 0px;
  margin-bottom: 1.5rem;
  padding-right: 4rem;
  padding-left: 4rem;
  list-style-type: none;
  font-size: 0.9rem;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 991px) {
  ul {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media screen and (max-width: 767px) {
  ul {
    padding-right: 0rem;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 479px) {
  ul {
    padding-left: 1rem;
    font-size: 0.8rem;
    letter-spacing: 0.02em;
  }
}

ol {
  margin-top: 0px;
  margin-bottom: 1.5rem;
  padding-right: 4rem;
  padding-left: 4rem;
  list-style-type: none;
  font-size: 0.9rem;
}

@media screen and (max-width: 991px) {
  ol {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media screen and (max-width: 767px) {
  ol {
    padding-right: 0rem;
    padding-left: 2rem;
  }
}

@media screen and (max-width: 479px) {
  ol {
    padding-left: 1rem;
    font-size: 0.8rem;
  }
}

li {
  position: relative;
  margin-bottom: 1rem;
}

ul > li::before {
  content: '';
  position: absolute;
  top: 0.45em;
  right: calc(100% + 0.4em);
  width: 0.4em;
  height: 0.4em;
  border: 1px solid #72686b;

  /* Dark Grey */
  border-radius: 50%;
  vertical-align: bottom;
}

/* Nested lists */
ul ul > li::before,
ol ul > li::before {
  border-style: dotted;
}

ol {
  counter-reset: custom-counter;
}

ol > li {
  counter-increment: custom-counter;
}

ol > li::before {
  content: counter(custom-counter);
  position: absolute;
  right: calc(100% + 0.33333em);
  padding-right: 0.33333em;

  /* Dark Grey */
  border-right: 1px solid #72686b;
  color: #72686b;

  /* Dark Grey */
}

/* Nested numeral lists. */
ul ol > li::before,
ol ol > li::before {
  opacity: 0.7;
}

li ul,
li ol {
  margin: 1rem -1rem 1.5rem;
}

@media (max-width: 320px) {
  li ul,
  li ol {
    margin: 1rem 0 1.5rem;
  }
}

/* Shortcut for uls that are not really lists. */
.unstyled-list > li::before {
  content: none;
  margin: 0;
  padding: 0;
  font-size: 1em;
  letter-spacing: normal;
}

.unstyled-list li {
  margin-bottom: 0;
}

.unstyled-list ul > li::before {
  border-style: none;
}

.list--two-column {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
}

.list--two-column li + li {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.list--three-column {
  -webkit-columns: 3;
  -moz-columns: 3;
  columns: 3;
}

.list--three-column li + li {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

.list--two-column li,
.list--three-column li {
  /* Avoid breaking list item content across columns. */
  break-inside: avoid;

  /* A Safari bug prevents rendering of overflow content in elements wrapped in
   * a columned container. Using transform forces the element into its own
   * visibility layer, which circumvents the issue. */
  transform: translate3d(0, 0, 0);
}

.list--big-and-bold {
  font-size: 1.2rem;
  font-weight: 800;
}

.list--unindented {
  padding-right: 0;
  padding-left: 0;
}

.list--indented-right {
  padding-right: 4rem;
  padding-left: 0;
}

.list--no-icon > li::before {
  content: none;
}

.dek {
  margin: 1rem 0;
  color: #72686b;
  font-family: Inter, sans-serif;
  font-size: 0.9rem;
  font-weight: 800;
  letter-spacing: 0.03em;
  line-height: 1.4;
  text-transform: uppercase;
}
