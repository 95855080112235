.container {
  position: relative;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 991px) {
  .container {
    width: 88%;
  }
}

@media screen and (max-width: 479px) {
  .container {
    width: 85%;
  }
}

.container.container--frame-3 {
  position: absolute;
}

.container.container--center {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.container--wide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.container--wide.container--justify-content-center {
  -webkit-justify-content: center;
  justify-content: center;
}

@media screen and (max-width: 991px) {
  .container--wide {
    width: 88%;
  }
}

@media screen and (max-width: 479px) {
  .container--wide {
    width: 85%;
  }
}

.container--narrow {
  max-width: 800px;
}

@media screen and (min-width: 992px) {
  .container--left-leaning {
    padding-right: 30%;
  }
}

.container.container--flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-content {
  background-color: #f3f2ef;
  color: #231f20;
}

.block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 20vh;
  padding-bottom: 20vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 767px) {
  .block {
    min-height: 0vh;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.block--case-study-hero {
  min-height: auto;
  padding-top: 8rem;
  padding-bottom: 7rem;
}

@media screen and (max-width: 767px) {
  .block--case-study-hero.block--mobile-shallow {
    padding-top: 8rem;
  }
}

.block--pane {
  min-height: 0vh;
}

.block--narrative {
  overflow: hidden;
}

.overflow--auto {
  overflow: visible;
}

.block--case-study-csr-pane {
  padding-top: 8rem;
  padding-bottom: 3.5rem;
}

.block--case-study-pane {
  min-height: 0vh;
  margin-bottom: -5.3rem;
  padding: 0;
}

.block--case-study-results {
  padding: 80px 0;
}

.block--case-study-pane .container {
  position: relative;
  height: 30vw;
}

.block--case-study-pane .case-study__screen {
  position: absolute;
  z-index: 1;
  top: -2.666rem;
  left: 50%;
  transform: translate(-50%, 0);
}

.block--pane.background-color--white {
  color: #231f20;
}

.block--shallow {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.block--twos {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f3f2ef;
  color: #000;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
}

.w-flex-block {
  display: flex;
}

.block__background-image {
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.block__background-image picture {
  display: block;
  width: 100%;
  height: 100%;
}

.block__background-image img {
  display: block;
  width: 100%;
  max-width: none;
  min-height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.layout__region {
  position: relative;
  padding-top: 6rem;
}

@media screen and (max-width: 767px) {
  .layout__region {
    padding-top: 4rem;
  }
}

.layout-sidebar-first {
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 84%;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

@media screen and (max-width: 991px) {
  .layout-sidebar-first {
    width: 88%;
  }
}

@media screen and (max-width: 767px) {
  .layout-sidebar-first {
    width: 92%;
    padding-bottom: 6rem;
  }
}

/* Table Styles */
table {
  margin: 0 0 1.5em;
  text-align: left;
}

thead {
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

tr {
  border-bottom: 1px solid #231f20;

  /* Black */
  vertical-align: top;
}

th,
td {
  padding: 1em 0;
}

th:not(:first-child),
td:not(:first-child) {
  padding-left: 0.5em;
}

.mobile-display--only {
  display: none;
}

@media (max-width: 991px) {
  .desktop-display {
    display: none;
  }

  .mobile-display--only {
    display: block;
    margin: 0 auto;
  }
}
