/* Icon adjustments when applied to service iconography. */
.icon--services {
  font-size: 150%;
  line-height: 0.9;
  vertical-align: -20%;
}

.block__icon {
  display: inline-block;
  margin-bottom: 1.5rem;
  font-family: Icons, sans-serif;
  font-size: 4rem;
  line-height: 1;
}

@media screen and (max-width: 767px) {
  .block__icon {
    font-size: 3rem;
  }
}

.icon {
  font-family: Icons, sans-serif;
  font-weight: 400;
}

.card__cta-icon {
  position: relative;
  top: 2px;
}

.block__icon.block__icon--gimbal {
  z-index: 2;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 767px) {
  .block__icon.block__icon--gimbal {
    width: 3rem;
    height: 3rem;
  }
}

.block__icon.block__icon--absolute {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 2;
  top: 110%;
  left: 50%;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 4rem;
  height: 4rem;
  margin-bottom: 0em;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 767px) {
  .block__icon.block__icon--absolute {
    width: 3rem;
    height: 3rem;
  }
}

/* Hero Icon: Animated Gimbal */
.block__icon--gimbal {
  animation: gimbal 4s infinite alternate linear;
}

@media (max-width: 767px) {
  .block__icon--gimbal {
    animation: gimbalSmall 4s infinite alternate linear;
  }
}

@keyframes gimbal {
  from {
    width: 0.25rem;
    opacity: 0.6;
  }

  to {
    width: 4rem;
    opacity: 1;
  }
}

@keyframes gimbalSmall {
  from {
    width: 0.1rem;
    opacity: 0.6;
  }

  to {
    width: 3rem;
    opacity: 1;
  }
}
