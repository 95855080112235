.block--page-title {
  min-height: 0px;
  padding-top: 12rem;
  padding-bottom: 8rem;
  overflow: hidden;
}

.block--page-title.overflow--auto {
  overflow: visible;
}

@media screen and (max-width: 991px) {
  .block--page-title {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }
}

@media screen and (max-width: 767px) {
  .block--page-title {
    padding-top: 8rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 479px) {
  .block--page-title {
    padding-top: 6rem;
  }
}

.block--page-title.overflow--reset {
  overflow: initial;
}

.block--hero {
  z-index: 2;
}

@media screen and (max-width: 767px) {
  .block--hero {
    min-height: 100vh;
  }
}

.block--hero-shallow {
  min-height: 0vh;
  padding-top: 10rem;
  padding-bottom: 4rem;
  background-color: #000;
  color: #f3f2ef;
}

@media screen and (max-width: 767px) {
  .block--hero.block--mobile-shallow {
    min-height: 0px;
    padding-top: 8rem;
  }
}

.hero__subtitle,
.hero__summary {
  max-width: 1000px;
  margin: 2rem auto 0rem;
  font-size: 1.75rem;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.3;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .hero__subtitle {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 767px) {
  .hero__subtitle {
    margin-top: 1rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 479px) {
  .hero__subtitle {
    font-size: 1rem;
  }
}

.hero__title {
  margin-top: 0rem;
  margin-bottom: 0rem;
  margin-left: 0em;
  font-size: 6.5vw;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .hero__title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .hero__title {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 479px) {
  .hero__title {
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 767px) {
  .hero__summary {
    margin-top: 3rem;
    font-size: 1.25rem;
  }
}

.hero--generic .hero__summary {
  margin: 1rem auto 0;
  text-align: center;
}

@media screen and (min-width: 767px) {
  .hero--generic .hero__summary {
    margin-top: 3rem;
  }
}

@media screen and (min-width: 991px) {
  .hero--generic .hero__summary {
    max-width: 75%;
  }
}

.h6.hero__type {
  margin-top: 0rem;
  margin-bottom: 2rem;
  color: rgba(211, 211, 204, 0.6);
  text-align: center;
}

@media screen and (max-width: 767px) {
  .h6.hero__type {
    margin-bottom: 1rem;
  }
}

/* Gimbal Icon */
.gimbal__ring {
  position: absolute;
  width: 44%;
  height: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  border-color: #72686b;
}

.gimbal__ring.gimbal__ring-2 {
  -webkit-transform: rotate(55deg);
  -ms-transform: rotate(55deg);
  transform: rotate(55deg);
}

.gimbal__ring.gimbal__ring-3 {
  -webkit-transform: rotate(-55deg);
  -ms-transform: rotate(-55deg);
  transform: rotate(-55deg);
}

/* Styles Icon in hero of services detail page and insights page. */
.service-detail__icon {
  position: absolute;
  z-index: -1;
  top: 19.0985vh;
  left: -19.0985vw;
  opacity: 0.3;
  font-family: Icons, sans-serif;
  font-size: 61.803vw;
  line-height: 1;

  /* Service Vocabulary Iconography */

  /* Prevent the icon font from being selected. */
  user-select: none;
}

@media screen and (max-width: 767px) {
  .service-detail__icon {
    top: 6rem;
  }
}

@media screen and (max-width: 479px) {
  .service-detail__icon {
    font-size: 80vw;
  }
}

.service-detail__icon.gradient-clip.background-gradient {
  cursor: default;
}

.block--image-hero img {
  margin-top: 2rem;
}

@media screen and (min-width: 992px) {
  .block--image-hero img {
    margin-top: 0;
  }
}

.split-hero--vertically-centered-image .halves__media {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991px) {
  .split-hero--vertically-centered-image .container {
    flex-direction: column-reverse;
  }

  .split-hero--vertically-centered-image .halves__media {
    margin-bottom: 3rem;
  }

  .split-hero--vertically-centered-image .halves__column.halves__column--wide {
    text-align: center;
  }

  .block--hero-landing.split-hero--vertically-centered-image .hero__title {
    text-align: center;
  }

  .body--landing .block--page-title .button__wrapper {
    padding-top: 0;
  }

  .w-4ygosfg .button__wrapper {
    justify-content: center !important;
  }
}

@media (min-width: 992px) {
  .split-hero--vertically-centered-image .hero__summary {
    text-align: left;
  }
}
