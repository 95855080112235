/* Slider Menu | What we do */
.services-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 2;
  top: 50%;
  right: 0%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  margin-bottom: 0em;
  padding-right: 0vw;
  padding-left: 0vw;
  -webkit-transform: translate3d(100%, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -ms-transform: translate3d(100%, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform: translate3d(100%, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition: transform 160ms var(--transition-timing-function);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

.services-menu--reveal {
  transform: translate3d(0%, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.services-menu__item-label {
  right: 110%;
  padding-right: 10px;
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  font-size: 0;
  font-weight: 400;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .services-menu__item-label {
    display: none;
  }
}

.services-menu--reveal {
  transform: translate3d(0%, -50%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.services-menu__item {
  margin-bottom: 0em;
}

.services-menu__item::before {
  display: none;
}

.services-menu__item-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-right: 0em;
  margin-left: 0em;
  padding: 0.25rem 1.3333rem 0.25rem 0.25rem;
  background-image: none;
  color: rgba(114, 104, 107, 0.8);
  font-size: 0.7rem;
  line-height: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .services-menu__item-link {
    padding-right: 0.5rem;
  }
}

@media screen and (max-width: 479px) {
  .services-menu__item-link {
    padding-right: 0.3rem;
  }
}

.services-menu__item-link.w--current {
  color: #000;
}

.services-menu__item-link:focus .services-menu__item-label,
.services-menu__item-link:hover .services-menu__item-label {
  opacity: 1;
  font-size: 0.7rem;
}

.icon.icon--services-menu {
  font-size: 1.333rem;
}

@media screen and (max-width: 479px) {
  .icon.icon--services-menu {
    font-size: 1.15rem;
  }
}
