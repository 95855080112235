/* ~ ~ ~ BUTTON STYLING ~ ~ ~ */
.button__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -ms-flex-pack: distribute;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 767px) {
  .button__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

.button__wrapper.button__wrapper--left-aligned {
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  padding-left: 0;
}

.button__wrapper.button__wrapper--spacing-top {
  margin-top: 2rem;
}

@media screen and (max-width: 767px) {
  .button__wrapper.button__wrapper--spacing-top {
    margin-top: 1rem;
  }
}

.button {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 1em 2em;
  overflow: hidden;
  border-radius: 2em;
  background-color: transparent;
  box-shadow: inset 0 0 0 1px #231f20;
  color: #231f20;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 0.9rem;
    letter-spacing: 0.02em;
  }
}

.button:hover {
  color: #ededea;
}

.button::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(0);

  /* Soft Black */
  transition: all 320ms cubic-bezier(0.22, 1, 0.36, 1);
  border-radius: 2rem;
  opacity: 0;
  background-color: #231f20;
}

.button:hover::after {
  transform: scale(1);
  border-radius: 1.5rem;
  opacity: 1;
}

.button__text {
  position: relative;
  z-index: 1;
}

.button__text.icon {
  position: relative;
  bottom: -0.1em;
  margin-left: 0.5em;
  font-weight: bold;
}

.button__text.icon {
  margin-left: 0.5em;
}

.button.button--white {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-color: #ededea;
  box-shadow: inset 0 0 0 1px #ededea;
  color: #ededea;
}

.button.button--white:hover {
  color: #231f20;
}

.button--white::after {
  z-index: -1;
  background-color: #f3f2ef;
}

.button.button--secondary {
  background-color: #2d65e7;
  box-shadow: none;
  color: #f3f2ef;
}

.button.button--secondary:hover {
  color: #231f20;
}

.button--secondary::after {
  background-color: #c2c700;

  /* Yellow */
}

.button.button--tertiary {
  box-shadow: none;
}

.button--tertiary::after {
  content: '';
  position: absolute;
  top: calc(50% - 0.1em);
  right: 0;
  left: 0;
  height: 0.25em;
  transform: scale(0.8, 1);
  border-radius: 0;
  opacity: 0.2;

  /* Tertiary Gradient */
  background-image: radial-gradient(circle farthest-corner at 120% -20%, #396eea 10%, rgba(57, 110, 234, 0) 50%), radial-gradient(circle farthest-corner at 40% -30%, #fc00a1, rgba(252, 0, 161, 0) 54%), radial-gradient(circle farthest-corner at 60% 20%, #00426a 16%, rgba(0, 66, 106, 0)), radial-gradient(circle farthest-corner at 0% 60%, #396eea, rgba(57, 110, 234, 0)), linear-gradient(328deg, #fc00a1 30%, rgba(252, 0, 161, 0));
}

.button--tertiary:hover::after {
  top: 0;
  height: 100%;
  transform: scale(1, 1);
  border-radius: 2em;
  opacity: 1;
}

.button.button--tertiary.button--white:hover {
  color: #ededea;
}

.button--white.button--tertiary::after {
  opacity: 0.8;
}

.button--white.button--tertiary:hover::after {
  opacity: 1;
}

.button.button--filter:hover {
  z-index: 1;
}

.button.button--filter::after {
  z-index: -1;
}

.button--filter {
  position: relative;
  cursor: pointer;
}

.button--filter [type=radio] {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.button.button--active,
.button.button--filter[data-filter-active='true'] {
  z-index: 1;
  box-shadow: inset 0 0 0 1px #c2c700;
  color: #231f20;
}

.button.button--active::after,
.button.button--filter[data-filter-active='true']::after {
  z-index: -1;
  transform: scale(1);
  opacity: 1;
  background-color: #c2c700;
}
