@font-face {
  font-family: 'Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/CHQ-Icons.woff') format('woff');
  font-display: block;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Inter-Regular.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'JetBrains Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/JetBrainsMono-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Larken';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/EllenLuff%20-%20Larken%20Black%20Italic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Larken';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/EllenLuff%20-%20Larken%20Italic.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Larken';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/EllenLuff%20-%20Larken%20Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Larken';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/EllenLuff%20-%20Larken%20Black.otf') format('opentype');
  font-display: swap;
}
