.x-axis-scroller {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  margin-bottom: 4rem;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
}

@media screen and (max-width: 767px) {
  .x-axis-scroller {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 3rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -ms-flex-align: center;
  }
}

.x-axis-scroller__heading {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: 0rem 1rem 0rem 0em;
  color: #72686b;
  line-height: 1;
  white-space: nowrap;
  -webkit-box-flex: 0;
}

@media screen and (max-width: 767px) {
  .x-axis-scroller__heading {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

.x-axis-scroller__menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0rem;
  padding-right: 0rem;
  padding-left: 0rem;
  overflow-x: auto;
  overflow-y: hidden;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .x-axis-scroller__menu {
    max-width: 100%;
  }
}

/* Horizontal Scrolling Menu */
.x-axis-scroller__menu li::before {
  content: none;
}

.x-axis-scroller__menu-item {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 0rem;
}

.x-axis-scroller__menu-item-link {
  white-space: nowrap;
}
