@keyframes fade {
  0% {
    opacity: 0;
  }

  13% {
    opacity: 1;
  }

  87% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  13% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.featured-hero__wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 90%;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

/* Fade in and out Animation */
.animation--hero-fade {
  animation: fade 2320ms var(--transition-timing-function);
}

/* Fade in Animation */
.animation--hero-fade-in {
  animation: fade-in 2320ms var(--transition-timing-function);
}

.featured-hero__doorway {
  position: absolute;
  z-index: -1;
  width: 22vw;
  height: 27vw;
  -webkit-transform: perspective(1000px);
  transform: perspective(1000px);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  border-width: 1px;
  border-style: solid;
  border-top-left-radius: 12vw;
  border-top-right-radius: 12vw;
  border-color: #72686b;
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .featured-hero__doorway {
    width: 30vw;
    height: 37vw;
    border-top-left-radius: 15vw;
    border-top-right-radius: 15vw;
  }
}

@media screen and (max-width: 479px) {
  .featured-hero__doorway {
    width: 38vw;
    height: 52vw;
    border-top-left-radius: 19vw;
    border-top-right-radius: 19vw;
  }
}

.featured-hero__wrapper a {
  font-style: italic;
}

.title__slider {
  position: relative;
  width: 100%;
  height: 6.5vw;
}

@media screen and (max-width: 991px) {
  .title__slider {
    height: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .title__slider {
    height: 2.25rem;
  }
}

@media screen and (max-width: 479px) {
  .title__slider {
    height: 1.75rem;
  }
}

.title__slider-text {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  margin-top: 0rem;
  margin-bottom: 0em;
  margin-left: 0em;
  opacity: 0;
  font-size: 6.5vw;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.04em;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .title__slider-text {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) {
  .title__slider-text {
    font-size: 2.25rem;
  }
}

@media screen and (max-width: 479px) {
  .title__slider-text {
    font-size: 1.75rem;
  }
}

.title__slider-text.slider-text--final {
  font-style: normal;
  font-weight: 900;
  letter-spacing: -0.02em;
}

/* Styles to support the hero animation. */
[data-animate-hero] .h1 {
  opacity: 1;
}

.js-enabled [data-animate-hero] .h1 {
  opacity: 0;
}

/* Animation transitions needed for the h1 if js is available. */
.js-enabled [data-animate-hero] .h1 {
  -webkit-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(0.5, 0.5, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
}

[data-animate-hero] .h1--transition {
  transition: all var(--speed-slow) var(--transition-timing-function);
}

.js-enabled [data-animate-hero="reduce"] .h1,
.js-enabled [data-animate-hero] .h1--reveal {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  opacity: 1;
}

.js-enabled [data-animate-hero] .featured-hero__doorway--transition {
  transition: all var(--speed-fast) var(--transition-timing-function);
}

.js-enabled [data-animate-hero] .featured-hero__doorway {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(-90deg) rotateY(0) rotateZ(0) skew(0, 0);
}

.js-enabled [data-animate-hero="reduce"] .featured-hero__doorway,
.js-enabled [data-animate-hero] .featured-hero__doorway--reveal {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform-style: preserve-3d;
}

[data-animate-hero] .title__slider * {
  transition: all var(--speed-fast) var(--transition-timing-function);
}

[data-animate-hero] .title__slider-text:last-of-type {
  opacity: 1;
}

[data-animate-hero] .title__slider-text,
.js-enabled [data-animate-hero] .title__slider-text:last-of-type {
  opacity: 0;
}

/* Graceful degradation of animations if JS doesn't run for user. */
.no-js [data-animate-hero] .h1 {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);
  opacity: 1;
}

.no-js [data-animate-hero] .title__slider-text:last-of-type {
  opacity: 1;
}

.no-js [data-animate-hero] .featured-hero__doorway {
  -webkit-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -moz-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  -ms-transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0) rotateZ(0) skew(0, 0);
  transform-style: preserve-3d;
}
