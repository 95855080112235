/* This variable is iterated on by the $window.scrollTop() function in script before footer. */
:root {
  --gradient-degree: 0deg;
}

/* ~ ~ ~ HEADER STYLES ~ ~ ~ */
.header {
  position: relative;
  z-index: 10;
  top: 0%;
  right: 0%;
  bottom: auto;
  left: 0%;

  /* Make the header take up 0 height so that there is no jump when its
   * position is changed to fixed with .header--scrolling. Note that this
   * element’s overflow is visible. */
  height: 0;
  overflow: visible;
}

.header .container--wide {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media screen and (max-width: 991px) {
  .header .container--wide {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
}

.body--dark .header,
.header--dark {
  background-color: transparent;
  color: #f3f2ef;
}

/* Header Brand & Logo */
.progress {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  overflow: hidden;
  border-radius: 50%;
}

.progress__fill {
  position: absolute;
  z-index: 2;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  border-radius: 50%;
  background-color: #f3f2ef;
}

.progress__stroke {
  position: absolute;
  z-index: 1;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  -webkit-transition: all 280ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 280ms cubic-bezier(0.23, 1, 0.32, 1);
  background-image: conic-gradient(#f3f2ef 0deg, transparent 0deg, transparent var(--gradient-degree), #f3f2ef var(--gradient-degree));
}

.brand__logo {
  display: block;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  -webkit-transition: color 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: color 320ms cubic-bezier(0.23, 1, 0.32, 1);
  color: #000;
  font-family: Icons, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
  -webkit-box-flex: 0;
}

.brand__logo:hover {
  color: #000;
}

@media screen and (max-width: 991px) {
  .brand__logo {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 479px) {
  .brand__logo {
    font-size: 0.925rem;
  }
}

/* Header variant with white text. */
.body--dark .brand__logo,
.header--dark .brand__logo {
  color: #f3f2ef;

  /* White */
}

.brand__icon {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  z-index: 3;
  left: 1.4%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  transform: scale(0);
  transform-origin: 50%;
  transition: opacity 320ms cubic-bezier(0.23, 1, 0.32, 1), color 320ms cubic-bezier(0.23, 1, 0.32, 1), transform 320ms cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  color: #231f20;
  font-family: Icons, sans-serif;
  font-size: 2rem;
  line-height: 0;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .brand__icon {
    font-size: 1.5rem;
  }
}

.brand__icon--arrow {
  position: relative;
  z-index: 3;
  margin-top: -2px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  font-size: 1.5rem;
}

@media screen and (max-width: 991px) {
  .brand__icon--arrow {
    font-size: 1.2rem;
  }
}

.brand__icon--hummingbird {
  position: relative;
  z-index: 3;
  padding-top: 2px;
  padding-right: 1px;
}

.brand__icon:hover .brand__icon--arrow {
  transform: scale(1, 1);
  opacity: 1;
}

.brand__icon:hover .brand__icon--hummingbird {
  transform: scale(0, 0);
  opacity: 0;
}

.menu__item {
  display: block;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0.5em;
  padding-left: 0.5em;
  background-position: 100% 50%;
  color: #000;
  font-weight: 400;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.menu__item:hover,
.menu__item.w--current {
  /* Webflow automatically applies .current when current page */
  background-size: 100% 0.175em;
}

.menu__item.menu__item--search {
  margin-right: -0.5em;
  padding: 0.5em;
  font-family: Icons, sans-serif;
  font-size: 1.25rem;
  line-height: 1;
}

/* Menu Styles */
.menu--mini {
  display: none;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-bottom: 0px;
  padding-right: 0vw;
  padding-left: 0px;
  list-style-type: none;
  -webkit-transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  transform-origin: 100% 0%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
}

@media screen and (min-width: 768px) {
  .menu--mini {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.menu--mini__li {
  display: block;
  margin-left: 0.5em;
  font-size: 1rem;
}

/* Header variant with black text. */
.menu--mini .menu__item {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
}

/* Style tweaks for accessibility */
.header--scrolling .menu--mini,
.header--scrolling .brand__logo {
  visibility: hidden;
}

.body--dark .menu--mini .menu__item,
.header--dark .menu--mini .menu__item {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  color: #f3f2ef;

  /* White */
}

.body--dark .menu__button .menu__button-line,
.header--dark:not(.header--scrolling) .menu__button .menu__button-line {
  background-color: #f3f2ef;

  /* White */
}

.menu__button {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  right: 2.5%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  -webkit-transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 50%;
  opacity: 0;
  background-color: #f3f2ef;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .menu__button {
    right: 6%;
  }
}

@media screen and (max-width: 767px) {
  .menu__button {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-radius: 0%;
    opacity: 1;
    background-color: transparent;
  }
}

@media screen and (max-width: 479px) {
  .menu__button {
    right: 7.5%;
  }
}

.menu__button-line {
  position: absolute;
  right: 10%;
  left: 10%;
  height: 1px;
  background-color: #231f20;
}

/* .menu__button-line.line--bottom {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
} */

.menu__button-line.button-line--top {
  top: 25%;
}

.menu__button-line.button-line--bottom {
  bottom: 25%;
}

.menu__button:hover .menu__button-line {
  background-color: #396eea;
}

/* Mega version submenu Link */
.submenu__li-link {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
}

/* Mega Menu "Contact us" Button */
.menu--mega__button {
  background-image: none;
}

.menu--mega__button .button__text::after {
  content: '';
  margin-left: 0.3333em;
  font-family: Icons;
  font-size: 130%;
  line-height: 0.8;
  vertical-align: -20%;
}

/* Collapse container element to keep it from interfering with content while
 * user is scrolling.
 */
.header--scrolling > .container--wide {
  height: 0;
}

.brand__icon,
.header--scrolling .menu__button {
  position: fixed;
  top: 0.9rem;
  width: 4rem;
  height: 4rem;
}

.header--scrolling .progress {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.header--scrolling .menu__button {
  right: 1.4%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.header--scrolling .menu__button .menu__button-line {
  right: 25%;
  left: 25%;
  background-color: #231f20;

  /* Black */
}

.header--scrolling .button-line--top {
  top: 33%;
}

.header--scrolling .button-line--bottom {
  bottom: 33%;
}

.header--scrolling .brand__icon,
.header--scrolling .menu__button {
  transform: scale(1, 1);
  opacity: 1;
}

.header--scrolling .brand__logo {
  height: 0;
  opacity: 0;
}

.header--scrolling .menu--mini {
  transform: scale(0, 0);
  opacity: 0;
}

.header--scrolling .brand__icon:hover {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 1;
  color: #396eea;
}

/* ≤ Narrow Desktop Adjustments */
@media (max-width: 991px) {
  .brand__icon,
  .header--scrolling .menu__button {
    top: 0.7rem;
  }

  .brand__icon {
    left: 4.5%;
  }

  .header--scrolling .menu__button {
    right: 4.5%;
  }
}

/* ≤ Tablet Adjustments */
@media (max-width: 768px) {
  .brand__icon,
  .header--scrolling .menu__button {
    top: 0.9rem;
    width: 3rem;
    height: 3rem;
  }

  .brand__icon {
    left: 5.2%;
  }

  /* Override non-scrolling, default visible state of menu button. */
  .header--scrolling .menu__button {
    right: 5.2%;
    border-radius: 50%;
    background-color: #f3f2ef;

    /* White */
  }
}

/* ≤ Mobile Landscape Adjustments */
@media (max-width: 478px) {
  .brand__icon {
    left: 4.75%;
  }

  .header--scrolling .menu__button {
    right: 4.75%;
  }
}
