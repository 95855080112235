.privacy-consent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  z-index: 10;
  bottom: 2rem;
  left: 50%;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 61.803vw;
  max-width: 800px;
  padding: 2rem 3rem 2rem 3.5rem;
  -webkit-transform: translate(-50%, 100%) scale(0.5, 0.5) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -ms-transform: translate(-50%, 100%) scale(0.5, 0.5) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform: translate(-50%, 100%) scale(0.5, 0.5) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  border-radius: 4rem;
  background-color: #f3f2ef;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.3), 0 10px 30px 10px rgba(0, 0, 0, 0.4);
  color: #231f20;
  font-size: 0.8rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .privacy-consent {
    width: 90vw;
  }
}

@media screen and (max-width: 767px) {
  .privacy-consent {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: stretch;
    align-items: stretch;
    padding: 1.5rem 2rem;
    border-radius: 2rem;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
  }
}

@media screen and (max-width: 479px) {
  .privacy-consent {
    font-size: 0.75rem;
  }
}

.privacy-consent p {
  margin-right: 1rem;
}

@media screen and (max-width: 767px) {
  .privacy-consent p {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

html:not(.reduce-motion) .privacy-consent {
  -o-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
}

/* Hide element by default, particularly for no js environments. */
.privacy-consent[aria-hidden] {
  visibility: hidden;
  opacity: 0;
}

/* Privacy consent notice hasn't been accepted by the user. */
html.js-enabled .privacy-consent[aria-hidden='false'] {
  visibility: visible;
  transform: translate(-50%, 0%) scale(1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  transition-delay: 3s;
  opacity: 1;
}

html.js-enabled .privacy-consent[aria-hidden='true'] {
  visibility: hidden;
  transform: translate(-50%, 100%) scale3d(0.5, 0.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  opacity: 0;
}
