.gridlist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: center;
  justify-content: center;
  width: calc(100% + 6rem);
  margin-right: -3rem;
  margin-bottom: -3rem;
  margin-left: -3rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

@media (max-width: 991px) {
  .gridlist {
    grid-auto-columns: 1fr;
    width: 100%;
    margin: 2rem 0 0;
  }
}

.gridlist__cell {
  position: relative;
  width: calc(50% - 6rem);
  margin: 3rem;
  font-weight: 400;
}

@media (max-width: 991px) {
  .gridlist__cell {
    width: 100%;
    margin: 2rem 0;
  }
}

/* Remove inherited padding for links. */
a.gridlist__cell {
  padding: 0;

  /* Negate global link blue to inherit the parent .block's color declaration instead. */
  color: inherit;
}

a.gridlist__cell:hover .gridlist__cell-cta {
  color: #000;

  /* True Black */
}

.gridlist.gridlist--three {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-auto-columns: 1fr;
  margin: -2rem;
}

@media screen and (max-width: 767px) {
  .gridlist.gridlist--three {
    margin: -1rem 0rem;
  }
}

/* Three in a row (desktop+) variant. */
.gridlist--three,
.gridlist--four {
  width: calc(100% + 4rem);
}

/* Four in a row (desktop+) variant. */
.gridlist--four .gridlist__cell {
  width: calc(25% - 3rem);
  margin: 1rem;
}

@media (max-width: 991px) {
  .gridlist--three {
    width: calc(100% + 4rem);
  }

  .gridlist--four {
    width: calc(100% + 2rem);
  }
}

@media (max-width: 767px) {
  .gridlist--three {
    width: 100%;
    margin: 2rem 0;
  }
}

.gridlist--three .gridlist__cell {
  width: calc(33.3333% - 4rem);
  margin: 2rem;
}

@media (max-width: 991px) {
  .gridlist--three .gridlist__cell {
    width: calc(50% - 4rem);
    margin: 2rem;
  }

  .gridlist--four .gridlist__cell {
    width: calc(50% - 2rem);
    margin: 1rem;
  }
}

@media (max-width: 767px) {
  .gridlist--three .gridlist__cell,
  .gridlist--four .gridlist__cell {
    width: 100%;
    margin: 1rem 0;
  }
}

#benefits .gridlist__cell-title {
  margin-top: 0em;
}

.gridlist__cell-icon {
  display: inline-block;
  margin-bottom: 1rem;
  font-family: Icons, sans-serif;
  font-size: 4rem;
  font-weight: 400;
  line-height: 0.8;
}

@media screen and (max-width: 767px) {
  .gridlist__cell-icon {
    left: 0%;
  }
}

.icon.gridlist__cell-icon {
  font-size: 300%;
  line-height: 1;
}

.gridlist__cell-list {
  margin-top: 1rem;
  margin-bottom: 0em;
  padding-right: 0vw;
  padding-left: 0rem;
  text-align: left;
}

.gridlist__cell.gridlist__cell--active {
  margin-right: 0em;
  margin-left: 0em;
  padding: 2rem;
  background-color: #d3d3cc;
}

.gridlist__cell.gridlist__cell--link {
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
}

.gridlist__cell-cta {
  -webkit-transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 320ms cubic-bezier(0.23, 1, 0.32, 1);
  color: #2d65e7;
  font-weight: 800;
}

.gridlist__cell-cta .icon {
  margin-left: 0.125em;
  vertical-align: bottom;
}

.gridlist__cell-image {
  width: 100%;
}

.gridlist--four .gridlist__cell-image {
  width: auto;
  max-width: 80%;
  height: 60px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.gridlist--four p {
  font-size: 0.9rem;
}

/* Remove browser-generated triangle icon. Chrome & FF seem to auto remove it whenever a summary is first child element. */
.gridlist details summary::-webkit-details-marker {
  display: none;
}

.gridlist details summary:hover {
  color: black;
}

.gridlist details summary .icon {
  display: inline-block;
  margin-right: 0.125em;
  transition: all 320ms cubic-bezier(0.22, 1, 0.36, 1);
  vertical-align: bottom;
}

.gridlist details[open] summary .icon {
  transform: rotateX(180deg);
}

/* Benefits details unordered list */
.gridlist__cell-list {
  text-align: center;
}

.gridlist__cell-list li::before {
  content: none;
}

/* Grid Lists | Life at Chromatic > Benefits section */

/* Insights featured card. */
.gridlist--three .gridlist__cell--featured {
  min-width: calc(66.6666% - 4rem);
}

@media (max-width: 991px) {
  .gridlist--three .gridlist__cell--featured {
    min-width: calc(100% - 4rem);
  }
}

/* Webflow does not provision the GUI augmenting of detail tag. */

/* "More Details" accordions */
.gridlist details summary {
  transition: all 320ms cubic-bezier(0.22, 1, 0.36, 1);
  color: #2d65e7;
  font-weight: 800;
  cursor: pointer;
}
