/* Utility animation classes */

/* Pulsing Animation */
.animation--pulse {
  animation: pulse 3s infinite alternate cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  20% {
    transform: scale(1.05);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.05);
  }

  35% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  65% {
    transform: scale(1.05);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

/* Spin Animation */
.animation--spin {
  animation: spin 100s infinite linear;
}

.animation--spin--faster {
  animation-duration: 8s;
}

@keyframes spin {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

/* Center align when applied to hanging block icons. */
.animation--spin.block__icon--absolute {
  left: calc(50% - 2rem);
}

@media (max-width: 767px) {
  .animation--spin.block__icon--absolute {
    left: calc(50% - 1.5rem);
  }
}

/* Apply transition only if a value is explicitly set on the attribute. */
[data-animate-onscroll-target="true"],
[data-animate-onscroll-target="false"] {
  transition: all var(--speed-slow) var(--transition-timing-function);
}

[data-animate-onscroll-target="false"] {
  transform: scale(0.9, 0.9);
  transform-style: preserve-3d;
  opacity: 0;
}

[data-animate-onscroll-target="true"] {
  transform: scale(1, 1);
  transform-style: preserve-3d;
  opacity: 1;
}

/* Apply transition only if a value is explicitly set on the attribute. */
[data-animate-curtain="true"],
[data-animate-curtain="false"] {
  --opacity: 0%;
  --width: 0vw;

  width: var(--width) !important;
  opacity: var(--opacity);
}

[data-toggle-target] {
  transition: all 0.25s ease-in-out;
}

[data-toggle-target][aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
}

[data-toggle-target][aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
}

[data-toggle-animation='true'][data-element-is-visible='false'] {
  animation-play-state: paused;
}

[data-toggle-animation='true'][data-element-is-visible='true'] {
  animation: autoScroll 160s infinite alternate linear;
}
