.background-gradient--animated {
  width: 100%;
  height: 100%;
}

.background-gradient--animated canvas {
  display: block;
  width: 100%;
  height: 100%;
}

.background--absolute {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
}

.background-gradient {
  background-color: #fff;
  background-image: linear-gradient(450deg, #ef243b, rgba(96, 97, 151, 0) 70%), linear-gradient(135deg, #396eea 30%, rgba(57, 110, 234, 0) 80%), linear-gradient(225deg, #c2c700 5%, rgba(194, 199, 0, 0) 80%), linear-gradient(0deg, #fc00a1, rgba(252, 0, 161, 0) 71%);
  color: #f3f2ef;
}

.background-gradient--secondary {
  background-color: #fff;
  background-image: radial-gradient(circle farthest-corner at 110% 40%, #00426a 19%, rgba(0, 66, 106, 0) 83%), radial-gradient(circle farthest-corner at 0% 0%, #396eea, rgba(57, 110, 234, 0) 59%), linear-gradient(48deg, #00b857 18%, rgba(0, 184, 87, 0));
  color: #f3f2ef;
}

.background-gradient--tertiary {
  background-color: #fff;
  background-image: radial-gradient(circle farthest-corner at 120% -20%, #396eea 10%, rgba(57, 110, 234, 0) 50%), radial-gradient(circle farthest-corner at 40% -30%, #fc00a1, rgba(252, 0, 161, 0) 54%), radial-gradient(circle farthest-corner at 60% 20%, #00426a 16%, rgba(0, 66, 106, 0)), radial-gradient(circle farthest-corner at 0% 60%, #396eea, rgba(57, 110, 234, 0)), linear-gradient(328deg, #fc00a1 30%, rgba(252, 0, 161, 0));
  color: #f3f2ef;
}

.background-color--white {
  background-color: #f3f2ef;
  color: #000;
}

.background-color--grey-lightest {
  background-color: #ededea;
  color: #000;
}

.background-color--grey-light {
  background-color: #d3d3cc;
  color: #000;
}

.background-color--grey {
  background-color: #b2b2ab;
}

.background-color--grey-dark {
  background-color: #72686b;
  color: #f3f2ef;
}

.background-color--true-black {
  background-color: #000;
  color: #ededea;
}

.background-color--black {
  background-color: #231f20;
  color: #d3d3cc;
}

.background-color--black-light {
  background-color: #2c2729;
  color: #d3d3cc;
}

/* @TODO: Move to Insights/Related Content styling sheet. */
.block.background-color--black-light {
  background-color: #2c2729;
  color: #f3f2ef;
}

.background-color--blue {
  background-color: #396eea;
  color: #000;
}

.background-color--blue-safe {
  background-color: #2d65e7;
  color: #fff;
}

.background-color--red {
  background-color: #ef243b;
}

.background-color--yellow {
  background-color: #c2c700;
}

.background-color--magenta {
  background-color: #fc00a1;
}

.background-color--orange {
  background-color: #f05123;
}

.background-color--green {
  background-color: #00b857;
}

.background-color--green-dark {
  background-color: #365f3b;
  color: #ededea;
}

.background-color--orange-dark {
  background-color: #93421e;
  color: #ededea;
}

.background-color--magenta-dark {
  background-color: #97386c;
  color: #ededea;
}

.background-color--yellow-dark {
  background-color: #897400;
  color: #ededea;
}

.background-color--red-dark {
  background-color: #8e002e;
  color: #ededea;
}

.background-color--blue-dark {
  background-color: #00426a;
  color: #ededea;
}
