.menu--mega {
  visibility: hidden;
  position: fixed;
  z-index: 10;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  -webkit-transform: translate(0px, -100%) scale(0.5);
  -ms-transform: translate(0px, -100%) scale(0.5);
  transform: translate(0px, -100%) scale(0.5);
  transform-style: preserve-3d;
  transition: all var(--speed-fast) var(--transition-timing-function);
  opacity: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

.menu--mega[aria-hidden="true"] {
  visibility: hidden;
  transform: translate3d(0px, 0%, 0px) scale3d(0.5, 0.5, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 0;
}

.menu--mega[aria-hidden="false"] {
  visibility: visible;
  transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
}

.header--scrolling .container--wide.menu--mega__container {
  height: 100vh;
}

.container--wide.menu--mega__container {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: space-between;
  align-content: space-between;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2.5vw;
  -webkit-box-align: start;
  -ms-flex-align: start;
  -ms-flex-line-pack: justify;
}

@media screen and (max-width: 991px) {
  .container--wide.menu--mega__container {
    padding-top: 1.75rem;
    padding-bottom: 6vh;
  }
}

@media screen and (max-width: 767px) {
  .container--wide.menu--mega__container {
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 479px) {
  .container--wide.menu--mega__container {
    padding-bottom: 6rem;
  }
}

.menu--mega__brand {
  display: block;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  color: #f3f2ef;
  font-family: Icons, sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1;
}

@media screen and (max-width: 991px) {
  .menu--mega__brand {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 479px) {
  .menu--mega__brand {
    font-size: 0.925rem;
  }

  .menu--mega__brand.w--current {
    width: 8rem;
  }
}

.menu--mega__brand:hover {
  color: #f3f2ef;
}

.menu--mega__ul {
  -webkit-align-self: flex-end;
  align-self: flex-end;
  width: 100%;
  margin-bottom: 0px;
  padding-right: 0vw;
  padding-left: 0px;
  list-style-type: none;
  font-size: 6vw;
  text-align: right;
  -ms-flex-item-align: end;
}

.menu--mega__li {
  display: block;
  margin-bottom: 0em;
}

.menu--mega__li--small {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  margin-top: 2rem;
  font-size: 1rem;
  letter-spacing: 0em;
  line-height: 1.2;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

@media screen and (max-width: 767px) {
  .menu--mega__li--small {
    margin-top: 1rem;
  }
}

/* Negate global unordered list styles. */
.menu--mini__li,
.menu--mega__li,
.menu--mega__footer li {
  margin-bottom: 0;
}

.menu--mini__li::before,
.menu--mega__li::before,
.submenu li::before {
  display: none;
}

/* Negate inherited link styles. */
.menu--mega__item {
  display: block;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  background-image: none;
  color: #f3f2ef;
  font-size: 10vh;
  letter-spacing: -0.03em;
  line-height: 1.1;
}

@media screen and (max-width: 991px) {
  .menu--mega__item {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .menu--mega__item {
    font-size: 10vh;
    letter-spacing: -0.01em;
  }
}

@media screen and (max-width: 479px) {
  .menu--mega__item {
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    font-size: 2.25rem;
  }
}

.menu--mega__item:hover {
  color: #f3f2ef;
}

.menu--mega__item-span {
  position: relative;
}

.menu--mega__item-span::after {
  content: '';
  position: absolute;
  top: calc(50% + 0.02em);
  right: 0;
  left: 100%;
  height: 4px;
  transition: all 640ms cubic-bezier(0.22, 1, 0.36, 1);
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 768px) {
  .menu--mega__item-span::after {
    height: 2px;
  }
}

.menu--mega__item:hover .menu--mega__item-span::after {
  right: -2.75%;
  left: -2.75%;
}

@media (max-width: 768px) {
  .menu--mega__item:hover .menu--mega__item-span::after {
    right: -6.75%;
    left: -6.75%;
  }
}

@media (max-width: 320px) {
  .menu--mega__item:hover .menu--mega__item-span::after {
    right: -8.75%;
    left: -8.75%;
  }
}

.button.button--white.menu--mega__button {
  margin-top: 0em;
  margin-bottom: 0em;
  margin-left: 0em;
  padding-right: 1.75em;
}

.menu--mega__button-line {
  position: absolute;
  right: 0px;
  left: 0px;
  height: 1px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #f3f2ef;
}

.menu--mega__button-line.button-line--nth-child-2 {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu__button-label {
  position: absolute;
  overflow: hidden;
  color: transparent;
  font-size: 0rem;
  line-height: 0;
}

.menu__button--mega {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  z-index: 1;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 2.75rem;
  height: 2.75rem;
  margin-top: -0.5em;
  margin-right: -0.25em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  background: transparent;
}

@media screen and (max-width: 767px) {
  .menu__button--mega {
    top: -0.75rem;
    margin-top: 0em;
  }
}

.menu__button--mega:hover {
  opacity: 0.6;
}

/* Smaller mega menu nav items */
.submenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 0em;
  padding-top: 0px;
  padding-right: 0vw;
  padding-left: 0vw;
  -webkit-box-align: end;
  -ms-flex-align: end;
}

@media screen and (max-width: 767px) {
  .submenu {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  }
}

@media screen and (max-width: 479px) {
  .submenu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.submenu__li {
  margin-right: 1em;
  margin-bottom: 0em;
  font-size: 0.9rem;
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .submenu__li {
    margin-right: 0em;
    margin-left: 1em;
  }
}

@media screen and (max-width: 479px) {
  .submenu__li {
    margin-top: 0.25em;
    margin-right: 0em;
  }
}

.submenu__li-link {
  display: block;
  color: hsla(0, 0%, 100%, 0.9);
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  .submenu__li-link {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
}

.submenu__li-link:hover {
  color: #fff;
}
