/* ~ ~ ~ BASE/GLOBAL STYLING ~ ~ ~ */
:root {
  --speed-slow: 640ms;
  --speed-fast: 320ms;
  --transition-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
}

html {
  height: 100%;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

body {
  background-color: #f3f2ef;
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

body:has(.menu--mega[aria-hidden="false"]) {
  overflow: hidden;
}

/* Remove top margin for headings that come first. */
.body--generic h2:first-child {
  margin-top: 0;
}

.body--dark {
  background-color: #231f20;
  color: #d3d3cc;
}

.list--nude {
  margin-bottom: 0em;
  padding-right: 0vw;
  padding-left: 0vw;
}

/* Negate list item pseudo-element. */
.footer__menu-item::before,
.article__byline li::before,
.list--nude li::before {
  content: none;
}

/* Remove bottom spacing from the last nested element in a blockquote. */
blockquote *:last-child {
  margin-bottom: 0;
}

.display--inline {
  display: inline;
}

.max-width--400 {
  max-width: 400px;
  margin: 0 auto;
}

.max-width--1600 {
  max-width: 1600px;
  margin: 0 auto;
}

.max-width--1600.halves {
  padding: 6rem 50px;
}

@media screen and (min-width: 1599px) {
  .max-width--1600.halves {
    padding-right: 0;
    padding-left: 0;
  }
}

.marginless--top {
  margin-top: 0rem;
}

.padding-top--small {
  padding-top: 2rem;
}

.padding-top--none {
  padding-top: 0 !important;
}

.padding-bottom--none {
  padding-bottom: 0;
}

.padding {
  padding: 2rem;
}

.paddingless {
  padding: 0px 0vw;
}

.position-absolute {
  position: absolute;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
}

.position-relative {
  position: relative;
}

.overflow--hidden {
  overflow: hidden;
}

.body--landing .block.bottom-padding--none,
.block.bottom-padding--none,
.bottom-padding--none {
  padding-bottom: 0;
}

.body--landing .block.bottom-padding--1rem,
.block.bottom-padding--1rem,
.bottom-padding--1rem {
  padding-bottom: 1rem;
}

.marginless {
  margin: 0rem;
}

.margin-bottom--small {
  margin-bottom: 1rem;
}

.top-margin--none {
  margin-top: 0;
}

.top-padding--none {
  padding-top: 0 !important;
}

.padding--none {
  padding: 0;
}

@media screen and (max-width: 767px) {
  .marginless {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.img--center {
  display: block;
  margin: 0 auto 1.5rem;
}

.transform--rotate-90deg {
  display: block;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.transform--rotate-180deg {
  display: block;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.transform--rotate-270deg {
  display: block;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

/* Box shadows */
.box-shadow--both {
  position: relative;
  box-shadow: 7px 7px 20px 5px rgba(0, 0, 0, 0.3), 0 0 2px 1px rgba(0, 0, 0, 0.4);
}

.box-shadow--both.box-shadow--light {
  box-shadow: 7px 7px 20px 5px rgba(0, 0, 0, 0.15), 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

/* Add white inset glow to shadow. */
.box-shadow--both::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: inset 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

.circle--small {
  position: absolute;
  top: -4rem;
  left: -4rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #fff;
}

.circle--extra-small {
  position: absolute;
  z-index: -1;
  top: -2rem;
  left: -2rem;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: #fff;
}

/* Create a halo out of the .circle--small element. */
.circle--halo::before {
  content: '';
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  left: 2px;
  border-radius: 50%;

  /* Needs to be adjusted per parent background color. */
  background-color: #f3f2ef;

  /* White */
}

/* Utility class for clipping text to its background color. */
.gradient-clip {
  padding: 1px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Utility for creating text wrapping effects for any adjacent inline element. */
.shape-outside {
  float: left;
  shape-outside: border-box;
}

.shape-outside.shape-outside--circle {
  width: 38.197vw;
  height: 38.197vw;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .shape-outside.shape-outside--circle {
    width: 50vw;
    height: 50vw;
  }
}

picture {
  display: block;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  white-space: nowrap;
}
