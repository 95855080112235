/* ~ ~ ~ INSIGHTS ARTICLE STYLES ~ ~ ~ */

/* When the Content Upgrade/Webform follows or precedes a normal text block add more spacing. */
.article__block:not(.article__block--webform) + .article__block--webform,
.article__block--webform + .article__block {
  margin-top: 6rem;
}

/* Remove top margin for headings that come first. */
.article__block h2:first-child {
  margin-top: 0;
}

.article__block p:last-child,
.article__block ul:last-child {
  margin-bottom: 0;
}

.article__block.background-color--black {
  margin-top: 6rem;
  margin-bottom: 6rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

@media screen and (max-width: 991px) {
  .article__block.background-color--black {
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .article__block.background-color--black {
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.article__block.article__block--webform {
  padding-top: 20vh;
  padding-bottom: 20vh;
}

@media screen and (max-width: 767px) {
  .article__block.article__block--webform {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.article__outdated-warning {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(239, 36, 59, 0.1);
  color: #8e002e;
  font-size: 0.8rem;
}

@media screen and (max-width: 991px) {
  .article__outdated-warning {
    width: 100%;
  }
}

/* Figure element within articles */
.article__figure {
  width: 100%;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 767px) {
  .article__figure {
    margin-bottom: 1rem;
  }
}

.article__figure.article__figure--left {
  width: 61.803%;
  margin-right: 2rem;
  margin-left: -15%;
  float: left;
}

@media screen and (max-width: 991px) {
  .article__figure.article__figure--left {
    width: 38.197%;
    margin-left: 0%;
  }
}

@media screen and (max-width: 767px) {
  .article__figure.article__figure--left {
    width: 100%;
    margin-right: 0rem;
    float: none;
  }
}

.article__figure.article__figure--right {
  width: 61.803%;
  margin-right: -15%;
  margin-left: 2rem;
  float: right;
}

@media screen and (max-width: 991px) {
  .article__figure.article__figure--right {
    width: 38.197%;
    margin-right: 0%;
  }
}

@media screen and (max-width: 767px) {
  .article__figure.article__figure--right {
    width: 100%;
    margin-left: 0rem;
    float: none;
  }
}

.article__figcaption {
  padding-top: 1rem;
  color: #72686b;
}

@media screen and (max-width: 991px) {
  .article__figcaption.p--small {
    padding-top: 0.5rem;
  }
}

/* Remove unordered list custom bullets. */
.article__terms {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  grid-auto-columns: auto;
  grid-auto-rows: auto;
  -ms-grid-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  -webkit-align-items: stretch;
  align-items: stretch;
  max-width: 100%;
  margin-bottom: 0em;
  margin-left: 50%;
  padding: 6rem 1rem;
  overflow: auto;
  -webkit-transform: translate(-50%, 0px);
  -ms-transform: translate(-50%, 0px);
  transform: translate(-50%, 0px);
  color: #b2b2ab;
  font-size: 0.7rem;
  letter-spacing: 0.05em;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
}

@media screen and (max-width: 767px) {
  .article__terms {
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-size: 0.65rem;
  }
}

.article__terms-item {
  display: inline-block;
  margin-right: 0.75em;
  margin-bottom: 0em;
  margin-left: 0.75em;
}

.article__terms-item::before {
  content: none;
}

.article__terms-item::after {
  content: '•';
  position: absolute;
  top: 0.175em;
  right: -1em;
}

.article__terms-item:last-of-type::after {
  display: none;
}

.article__terms-item-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  background-image: none;
  color: #72686b;
  font-weight: 400;
  letter-spacing: 0.03em;
  white-space: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.article__terms-item-link:hover {
  color: #000;
}

.article__terms-item-link.articles__terms--service {
  font-weight: 800;
  letter-spacing: 0.05em;
}

/* Byline Styles */
.page-title__byline-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  z-index: 1;
  right: 0px;
  left: 0px;
  flex-direction: column;
  -webkit-align-items: flex-start;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: -2.5rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
}

@media screen and (max-width: 991px) {
  .page-title__byline-wrapper {
    margin-top: -2rem;
  }
}

@media screen and (max-width: 767px) {
  .page-title__byline-wrapper {
    -webkit-box-pack: start;
    align-items: flex-start;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -ms-flex-pack: start;
  }
}

.page-title__byline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  padding: 0.75rem 0.75rem 0;
  border-radius: 2.5rem 2.5rem 0 0;
  background-color: #f3f2ef;
  color: #231f20;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

@media screen and (max-width: 991px) {
  .page-title__byline {
    padding: 0.5rem 0.5rem 0;
    border-radius: 2rem 2rem 0 0;
  }
}

@media screen and (max-width: 767px) {
  .page-title__byline {
    -webkit-align-items: flex-start;
    align-items: flex-start;
    margin-left: 4vw;
    border-radius: 1.9rem 1.9rem 0 0;
    -webkit-box-align: start;
    -ms-flex-align: start;
  }
}

@media screen and (max-width: 479px) {
  .page-title__byline {
    margin-left: 4.5vw;
  }
}

.byline__authors {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0em;
  padding: 0;
  font-size: 1em;
}

@media screen and (max-width: 991px) {
  .byline__authors {
    font-size: 0.9em;
  }
}

@media screen and (max-width: 767px) {
  .byline__authors {
    font-size: 0.8em;
  }
}

.byline__authors.byline__authors--cards {
  margin-right: 0.3333em;
  margin-left: -0.25em;
  font-size: 0.8em;
}

.authors__author {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 3.5em;
  height: 3.5em;
  margin-bottom: 0em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.authors__author + .authors__author {
  margin-left: -0.618em;
}

/* Negate global list styles. */
.authors__author::before {
  content: none;
}

.authors__author.authors__author--cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.authors__author-link {
  display: block;
  margin-right: 0em;
  margin-left: 0em;
  padding-right: 0em;
  padding-left: 0em;
  overflow: hidden;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  border-color: #f3f2ef;
}

@media screen and (max-width: 767px) {
  .authors__author-link {
    border-width: 2px;
  }
}

/* Desaturate and fade profile image when inactive author. */
.authors__author-link .author__img {
  filter: saturate(0%) contrast(150%);
  opacity: 0.6;
}

a.authors__author-link .author__img {
  filter: saturate(100%) contrast(100%);
  opacity: 1;
}

.background-color--white .author__name--cards {
  color: #231f20;

  /* Black */
}

.author__name {
  position: absolute;
  z-index: -1;
  right: auto;
  bottom: 110%;
  left: auto;
  -webkit-transform: translate(0px, 280%);
  -ms-transform: translate(0px, 280%);
  transform: translate(0px, 280%);
  -webkit-transition: transform 320ms cubic-bezier(0.23, 1, 0.32, 1), opacity 320ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 320ms cubic-bezier(0.23, 1, 0.32, 1), opacity 320ms cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  color: #f3f2ef;
  line-height: 1;
  white-space: nowrap;
}

@media screen and (max-width: 767px) {
  .author__name {
    right: 6vw;
    left: 6vw;
  }
}

@media screen and (max-width: 479px) {
  .author__name {
    right: 2.5vw;
    left: 7.5vw;
  }
}

.author__name.author__name--cards {
  z-index: 0;
  right: auto;
  left: auto;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.author__img {
  display: block;
  width: 100%;
  height: auto;
}

/* Show author's name when hovering over item. */
.authors__author:hover .author__name {
  transform: translateY(-90%);
  opacity: 1;
}

.authors__author--cards:hover .author__name {
  transform: translateY(0%);
}

.byline__date {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  line-height: 1.2;
}

.page-title__byline-wrapper .byline__date {
  color: #231f20;
}

@media screen and (max-width: 767px) {
  .byline__date {
    margin-top: 0.3rem;
    font-size: 0.75rem;
  }

  .page-title__byline-wrapper .byline__date {
    padding-left: 6vw;
  }
}

@media screen and (max-width: 479px) {
  .page-title__byline-wrapper .byline__date {
    padding-left: 7.5vw;
  }
}

.byline__date.byline__date--cards {
  margin-top: 0rem;
}

.byline__date.byline__date--page-title {
  position: absolute;
  top: 85%;
}

.icon--article__terms {
  margin-right: 0.25em;
  font-size: 150%;
  font-weight: 400;
}

.alert-message {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: rgba(239, 36, 59, 0.1);
  color: #8e002e;
  font-size: 0.8rem;
}

.alert-message--info {
  background-color: rgba(36, 157, 239, 0.1);
  color: #00426a;
}

.alert-message p {
  margin: 0;
}
