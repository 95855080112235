/* .circle__wrapper {
  position: fixed;
  left: 19.0985vw;
  top: 85vh;
} */

.circle__wrapper {
  /*
  /* CSS Variables are used as a means of passing the values to
  /* animate-circle.js.
  */
  --translateX: -50%;
  --translateY: -50%;
  --scaleX: 1;
  --scaleY: 1;
  --top: 110vh;
  --left: 50vw;

  position: fixed;
  top: var(--top);
  left: var(--left);
  width: 61.803vh;
  height: 61.803vh;
  transform: translate3d(var(--translateX), var(--translateY), 0px) scale3d(var(--scaleX), var(--scaleY), 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  will-change: transform;
}

.circle {
  width: 100%;
  height: 100%;
  animation: spin 20s infinite linear;
  border-radius: 50%;
  background-color: #fff;
}
