/* 404 page */

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 260px;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

/* 404 page icon */
.icon.utility-page-content__icon {
  margin-bottom: 0.5rem;
  font-size: 4rem;
}
